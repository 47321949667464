<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <e-date-time
                title="开始日期"
                :labelCol="8"
                :valueCol="15"
                v-model:value="queryParams.BeginDate"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <e-date-time
                title="结束日期"
                :labelCol="8"
                :valueCol="15"
                v-model:value="queryParams.EndDate"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <e-input
                title="商品名称"
                :labelCol="8"
                :valueCol="15"
                v-model:value="queryParams.GoodsName"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <e-tree-select
                :labelCol="8"
                :valueCol="14"
                title="商品分类"
                :fieldNames="replaceFields"
                v-model:value="queryParams.GoodsCategoryId"
                :options="treeData"
              />
            </a-col>
            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                @click="exportExcel"
                style="margin-left: 10px"
              >
                导出Excel
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'bookTime'">
              {{ $filters.formatDate(record.bookTime) }}
            </template>

          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from '@ant-design/icons-vue'
import { DishClass } from '@/apis/dish'
import page from '@/mixins/page'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { dataToTree } from '@/utils'

const api = new DishClass()
export default defineComponent({
  mixins: [page],
  components: {},
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const treeData = ref([])
    const replaceFields = {
      value: 'id',
      key: 'id'
    }
    return {
      treeData,
      replaceFields,
      columns: [
        {
          title: '分类',
          key: 'goodsCategoryName',
          dataIndex: 'goodsCategoryName',
          width: 100
        },
        {
          title: '主辅料名称',
          key: 'goodsTitle',
          dataIndex: 'goodsTitle'
        },

        {
          title: '数量',
          key: 'dishGoodsCount',
          dataIndex: 'dishGoodsCount',
          width: 100
        },
        {
          title: '菜品单位',
          key: 'dishGoodsUnit',
          dataIndex: 'dishGoodsUnit',
          width: 150
        },
        {
          title: '商品单位',
          key: 'goodsUnit',
          dataIndex: 'goodsUnit',
          width: 150
        }
      ],
      loading,
      height,
      width
    }
  },
  created () {
    this.queryParams.BeginDate = dayjs().startOf('day')
    this.queryParams.EndDate = dayjs().endOf('day')
    this.getDataList(1)
    this.getCategorys()
  },
  methods: {
    async getCategorys () {
      const resp =
        this.$store.state.data.ALL_GOODSCATEGORYS.length > 0
          ? this.$store.state.data.ALL_GOODSCATEGORYS
          : await this.$store.dispatch('GetAllGoodsCategorys')
      const data = dataToTree(resp, 0)
      this.treeData = data
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      if (q.BeginDate) {
        q.BeginDate = q.BeginDate.format('YYYY-MM-DD')
      }
      if (q.EndDate) {
        q.EndDate = q.EndDate.format('YYYY-MM-DD')
      }

      api.dishGoodsReport(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20,
        BeginDate: dayjs().startOf('day'),
        EndDate: dayjs().endOf('day')
      }

      this.getDataList(1)
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },

    exportExcel () {
      const q = { ...this.queryParams }
      q.Offset = (this.page.page - 1) * q.Limit

      if (q.BeginDate) {
        q.BeginDate = q.BeginDate.format('YYYY-MM-DD')
      }
      if (q.EndDate) {
        q.EndDate = q.EndDate.format('YYYY-MM-DD')
      }

      api.exportDishGoodsReport(q).then((resp) => {
        fileDownload(
          resp,
          '菜品主辅料统计' + dayjs().format('YYYYMMDDHHmmss') + '.xlsx'
        )
      })
    }
  }
})
</script>

<style lang="less" scoped></style>
